@import '../../../main';

.model {
  max-width: 580px;
  max-height: none;

  &:focus {
    outline: none;
  }
}

.wide {
  max-width: 1000px;
  width: 1000px;

  @include media('<=1000px') {
    max-width: 800px;
    width: unset;
  }

}

.modelContent {
  h2 {
    font-family: $font-secondary;
    font-style: normal;
    line-height: normal;
    font-size: 28px;
    color: $viridian;


    @include media('<tablet') {
      font-size: 24px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;

  i {
    font-size: 32px;
  }

  a {
    color: $viridian;
  }
}
