@import '../../../../main';

.textCollection {
  margin-top: 20px;
  padding-bottom: 10px;
  //width: 100%;
}

.textInput {
  //display: inline-block;
  flex-grow: 1;
  input {
    //width: 50%;
    margin-top: 0;
    height:20px;
  }
  input:not([type]) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.item {
  display: flex;
}

.icon {
  color: $greyish-brown ;
}

.dragIcon {
  cursor: grab;
}

.deleteIcon {
  cursor: pointer;
}

.itemAdd {
  min-width: 30px;
  height: 20px;
  line-height: 20px;
}



.bottomMenu {
  display: flex;
  flex-direction: row-reverse;
}
