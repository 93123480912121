@import '../../../../main';

.editTitle {
    max-width: 480px;
}

.editTitle > input[type=text]:not(.browser-default) {
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 28px;
    font-weight: 500;
    color: #4f4f4f;
    text-transform: capitalize;
    margin: 0;
}

