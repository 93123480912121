@import '../../../main';

:global {
  .loader {
    -webkit-animation: spin 1s infinite linear;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 0;

    svg{
      fill: $viridian;
    }
  }

  @keyframes:global(spin) {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
