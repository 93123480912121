@import '../../../../main';

.buttonContainer {
  display: flex;
  flex-wrap: wrap;

  max-height: 230px;

  overflow:scroll;
  justify-content: flex-start;
  margin-top: -30px;

  @include media('<tablet') {
    margin-top: 0;
    justify-content: space-between;
  }

}

.footer {
  display: flex;
  flex-grow: 1;
  min-height: 100px;
}
