@import '../../../../main';

.purpose-chips {
  margin-top: 0;

  //margin-top: 20px;
  //padding-bottom: 10px;

  @include media('<tablet') {
    margin-top: 20px;
  }

  //:focus {
  //  background-color: $viridian;
  //}
}

.purpose-chips {
  :global {
    .chip:focus {
      background-color: $viridian;
    }
  }
}

.purpose-chips + label:not(.label-icon) {
  -webkit-transform: translateY(-140%);
  -ms-transform: translateY(-140%);
  transform: translateY(-140%);
  left: 0;
}

.purpose-chips + label.active:after {
  margin-top: 0.5em;
}

.purpose-chips + label::after {
  display: block;
  position: absolute;
  top: 60px;
  -webkit-transition: .2s opacity ease-out, .2s color ease-out;
  -o-transition: .2s opacity ease-out, .2s color ease-out;
  transition: .2s opacity ease-out, .2s color ease-out;

  content: attr(data-error);
  color: #F44336;
  opacity: 1;

  margin-top: 0.5em;
}
