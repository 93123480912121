@import '../../main';

.model {
  max-width: 580px;
  max-height: none;

  &:focus {
    outline: none;
  }
}

.modelContent {
  h2 {
    font-family: $font-primary;
    font-style: normal;
    line-height: normal;
    font-size: 28px;
    color: $viridian;


    @include media('<tablet') {
      font-size: 24px;
    }
  }
}

.header {
  display: flex;
  justify-content: space-between;

  i {
    font-size: 32px;
  }

  a {
    color: $viridian;
  }
}

.sampleImage {
  display: flex;
  justify-content: center;
  @include media('<tablet') {
    //height: 200px;
  }

  img {
    height: auto;
    width: auto;
    max-width: 400px;
    max-height: 400px;
    @include media('<tablet') {
      max-width: 200px;
      max-height: 200px;
    }
  }
}


.downloadContainer {
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  flex-wrap: wrap;

  @include media('<tablet') {
    justify-content: center;

    :first-child {
      margin-top: 0;
    }
  }
}
