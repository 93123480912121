@import '../../main';

.header {
  background-color: white;
  box-shadow: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.headerContainer  {
  display: flex;
  height: 60px;
}

.leftMenu {
  flex: 1;
  display: flex;
}

.rightMenu {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;

}

.logo {
  svg {
    width: 200px;
    margin-top: 11px;

    @include media('<tablet') {
      width: 150px;
    }
  }
}

@include media('<tablet') {
  .header {
    margin-top: 5px;
  }
}
