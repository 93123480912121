@import '../../../main';


.bar {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.left {
  flex: 1;
  h1 {
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;;
    font-size: 28px;
    font-weight: 500;
    color: $greyish-brown;
    text-transform: capitalize;
    margin: 0;
  }
}

.right {
  //flex: 1;
  display: flex;
  justify-content: flex-end;
}

.markdown {
  opacity: 0.8;
}
