@import '../../../main';

.documentList {
  overflow-y: hidden;

  h1 {
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;;
    font-size: 28px;
    font-weight: 500;
    color: $greyish-brown;
    text-transform: capitalize;
    margin: 0;
  }
}

.documentLoaderWrapper {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: flex;
  align-items: center;
}

.left {
  flex: 1;
}

.right {
  flext: 1;
}

.items {
  margin-top: 20px;
  min-height: 200px;
}
