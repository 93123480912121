@import '../../../main';

.tabWrapper {
  border-bottom: 1px solid $warm-grey;
}

.tabs {
  display: inline;
}

.tabs .tab {
  display: inline-block;

  text-align: center;
  line-height: 48px;
  height: 48px;

  margin: 0;
}
.tabs .tab a {
  color: $warm-grey;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0 24px;
  font-size: 19px;

  @include media('<=tablet') {
    font-size: 14px;
  }

  @include media('<=phone') {
    padding: 0 12px;
  }

}

.tabs .tab a:hover {
  border-bottom: 5px solid $hover-greyish;
}

.tabs .tab a.active {
  font-weight: 500;
  color: $greyish-brown;
  border-bottom: 5px solid $viridian;
}


.tabs .tab:first-child a {
  padding: 0 28px 0 0;
}
