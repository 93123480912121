@charset "UTF-8";
body {
  font-family: Roboto, "Helvetica", serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px; }

.documentItem {
  border-bottom: 1px solid #BDBDBD;
  margin-bottom: 20px; }
  .documentItem h3 {
    font-family: 'IBM Plex Sans';
    font-size: 19px;
    font-weight: 500;
    margin: 0;
    padding: 0; }
    .documentItem h3 a {
      color: #4f4f4f; }

.documentMenu ul {
  margin: 0;
  display: flex;
  align-items: center;
  color: #4f4f4f;
  font-family: Roboto;
  font-size: 12px; }
  @media (max-width: 320px) {
    .documentMenu ul {
      font-size: 10px; } }

.documentMenu ul li {
  display: inline-block;
  height: 100%; }

.documentMenu ul li + li:before {
  margin-left: 10px;
  margin-right: 10px;
  color: #828282;
  content: "•"; }

.dropMenu li {
  display: block; }

.dropMenu li > a {
  color: #219653;
  font-size: 12px;
  font-family: Roboto; }

.materialIcons {
  font-size: 18px;
  font-weight: bold;
  color: #4f4f4f; }

.status {
  color: #219653;
  font-weight: bold; }
