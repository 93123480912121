@import '../../../../main';

.radio {

}

.label {
  font-family: Roboto, "Helvetica", serif;
  font-weight: 400;
  color: #4f4f4f;
  position: unset;
}


[type="radio"]:not(:checked) + span.label, [type="radio"]:checked + span.label {
  font-size: 18px;
  font-weight: 400;
}

[type="radio"]:checked + span.label:after, [type="radio"].with-gap:checked + span.label:after {
  background-color: $viridian;
  border-color: $greyish-brown;
}
