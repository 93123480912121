@import '../../../main';

.button {
  height: 40px;
  min-width: 140px;
  border-radius: 5px;
  border: solid 0.5px $viridian;
  padding: 0 20px;

  line-height: 40px;

  font-family: $font-secondary;
  font-weight: 500;
  font-size: 16px;
  text-align: center;

  color: $viridian;

  @include media('<=phone') {
    font-size: 12px;
    padding: 0 10px;
    min-width: 80px;
  }
}

.button:hover {
  background-color: $hover-greyish
}

.button.inverted {
  background-color: $viridian;
  color: white;
}

.button.inverted:hover {
  background-color: $warm-grey
}
