@import '../../main';

.stepTitle {
  font-family: $font-secondary;
  font-size: 18px;
  font-weight: 500;
  color: $greyish-brown;

  margin: 0 -12px;
  cursor: pointer;
  padding: 15.5px 44px 24px 64px;
  display: block;
}

.stepActions {
  padding: 0 0.75rem;

  button {
    min-width: 80px;

    @include media('<tablet') {
      min-width: 100%;
    }

  }
}
