@import '../../main';


.markdown {
  color: $greyish-brown;

  h1 {
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 28px;
    font-weight: 500;
  }

  h2 {
    font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
  }

  table {
    margin-bottom: 20px;
    width: 50%;

    @include media('<desktop') {
      width: 80%;
    }

    @include media('<tablet') {
      width: 100%;
    }
  }
}
