@import '../../../../main';

.select {
  li > span {
    font-size: 16px;
    color: $viridian;
    display: block;
    line-height: 22px;
    padding: 14px 16px;
  }
}
