@import '../../main';


.editor {
  :global {
    .stepper .step:before {
      background-color: $viridian;
      width: 35px;
      height: 35px;
      font-family: $font-secondary;
      font-size: 20px;
      line-height: 35px;
    }

    .stepper .step.active:before, ul.stepper .step.done:before {
      background-color: $viridian;
    }

    .incomplete {

    }

    .stepper .step-content {
      display: block;
    }

    .stepper {
      text-align: left;
      overflow-y:hidden;
    }

    .stepper.horizontal {
      min-height: 658px;
    }

    .stepper .step-content {
      display: block;
    }

    @media only screen and (min-width: 993px) {

      .stepper.horizontal .step-actions {
        position: relative;
        //overflow: auto;
      }

      .stepper.horizontal .step-content {
        //overflow: auto;
      }

      .stepper .step-content {
        //overflow: auto;
      }
    }
  }

}
