@import '../../main';

.footer {
  font-family: Roboto;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $warm-grey;

  @include media('<=phone') {
    font-size: 12px;
  }

  .footerContent {
    border-top: 1px solid $warm-grey;
    color: $warm-grey ul {
      list-style-type: none;
    }

    ul li {
      display: inline;
      margin-left: 10px;
      a {
        color: $warm-grey
      }
    }
  }


}
