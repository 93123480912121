@import '../../../main';

.avatar {
  display: flex;
  align-items: center;

  img {
    height: 32px;
    width: 32px;
    border-radius: 50%;

    @include media('<=phone') {
      height: 26px;
      width: 26px;
    }

    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25), 0 2px 2px 0 rgba(0, 0, 0, 0.25)
  }
}

.team {
  color: $greyish-brown;
  text-decoration: underline;
  margin-right: 20px;

  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  @include media('<=phone') {
    display: none;
  }

  position: relative;
  bottom: 8px;
}

.profileMenu {
  border: 1px solid $warm-grey;
  box-shadow: none;

  li {
    a {
      color: $greyish-brown;
      font-size: 12px;
    }
  }
}
