@import '../../../../main';

.slider {
  margin-top: 25px;
  margin-bottom: 30px;
  max-width: 400px;
  margin-left:20px;

  :global {
    .noUi-pips-horizontal {
      height: 40px;
    }

    .noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
      background-color: $viridian;
    }
  }


}

.label {
  font-family: Roboto, "Helvetica", serif;
  font-size: 18px;
  font-weight: 400;
  color: $viridian;
  position: unset;
  margin-top: 20px;
}
