@import '../../../../../main';

.button {
  width: 200px;
  height: 200px;

  background: #F2F2F2;
  border: 2px solid #828282;
  box-sizing: border-box;
  border-radius: 10px;

  font-family: $font-primary;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 18px;


  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $greyish-brown;
  text-align: center;

  cursor: pointer;

  margin-top: 30px;

  @include media('<desktop') {
    width: 150px;
    height: 150px;
  }

  @include media('<tablet') {
    width: 100%;
    height: 50px;
    font-size: 18px;
    margin-top: 0;
  }

  &:not(:first-child) {
    margin-left:20px;
    @include media('<tablet') {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  span {
    //font-size: 2vw;
    width: 90%;
    //white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.button:hover {
  background-color: $hover-greyish;
}

.icon {
  font-size: 80px;
  color: $viridian;
  margin-bottom: 20px;

  @include media('<desktop') {
    font-size: 60px;
    margin-bottom: 0px;
  }

  @include media('<tablet') {
    margin-bottom: 0;
    font-size: 24px;
  }
}
