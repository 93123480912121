@import '../../../../main';

.question {
  label {
    font-family: $font-primary;
    font-size: 18px;
    font-weight: 400;
    color: $greyish-brown;
    position: unset;
  }

  input {
    font-family: $font-primary;
  }

  &.helper-text {
    font-family: $font-primary;
  }
}

.inputField {
  margin-top: 0;
  margin-bottom: 0;
}

/*
Based on this: https://github.com/Dogfalo/materialize/blob/573e754a822c1cbf963f50d5ae6c0a249787b607/sass/components/forms/_input-fields.scss#L12
 */

input:not([type]),
input[type=text]:not(.browser-default),
input[type=password]:not(.browser-default),
input[type=email]:not(.browser-default),
input[type=url]:not(.browser-default),
input[type=time]:not(.browser-default),
input[type=date]:not(.browser-default),
input[type=datetime]:not(.browser-default),
input[type=datetime-local]:not(.browser-default),
input[type=tel]:not(.browser-default),
input[type=number]:not(.browser-default),
input[type=search]:not(.browser-default),
textarea.materialize-textarea {
  margin-top: 10px;

  // Focused label style
  &:focus:not([readonly]) + label {
    color: $viridian;
  }
  //// Focused input style
  &:focus:not([readonly]) {
    border-bottom: 1px solid $viridian;
    box-shadow: 0 1px 0 0 $viridian;
  }
  //
}
