@import '../../../main';

.reviewSection {
  display: flex;
  flex-direction: column;
}

.error {
  align-self: center;
  border: 1px solid red;
  padding: 20px;
  border-radius: 20px;

  width: 80%;
  min-width: 250px;
  display:flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: red !important;
    text-decoration: underline;
  }

  &.material-icons {
    width: 200px;
  }
}

.error_outline {
  align-self: flex-start;
  font-size:60px;
  color: red !important;
}

.prefix  {
  font-size: 12px;
  color: $greyish-brown;
}

.error_text {
  flex-grow: 1;
  margin-left: 10px;
}
