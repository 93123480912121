@import "~include-media/dist/include-media";
//@import '~materialize-css/sass/materialize';
//@import '~material-icons/css/material-icons';

@import "./variables";

//@import '~@ibm/plex/scss/ibm-plex.scss';
//@import '~@ibm/plex/scss/ibm-plex.scss';

$font-primary: Roboto, 'Helvetica', serif;
$font-secondary: 'IBM Plex Sans',serif ;

// Styles
body {
  font-family: $font-primary;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;

}
