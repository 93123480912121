@import '../../../../main';

.duration {
  margin-top: 25px;
  margin-bottom: 30px;

  :global {
    .noUi-horizontal .noUi-handle, .noUi-vertical .noUi-handle {
      background-color: $viridian;
    }
  }

}

.label {
  font-family: Roboto, "Helvetica", serif;
  font-size: 18px;
  font-weight: 400;
  color: $viridian;
  position: unset;
  margin-top: 20px;
}
