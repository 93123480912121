@import '../../../main';

.button {
  min-width: 250px;

  border-radius: 5px;
  border: solid 0.5px $viridian;
  line-height: 40px;

  font-family: $font-secondary;
  font-weight: 500;
  font-size: 16px;
  text-align: left;

  background-color: $viridian;
  color: white;

  padding: 10px 20px 0;
  display: flex;
  justify-content: center;

  margin-top: 20px;

  @include media('<=phone') {
    font-size: 12px;
    padding: 0 10px;
    min-width: 80px;
    margin-top: 10px;
  }

  @include media('<tablet') {
    width: 100%;
  }
}

.button:hover {
  background-color: $warm-grey
}

.icon {
  color: white;
  line-height: 30px;
  padding-right: 10px;

  svg {
    width: 36px;
    height: 36px;
    fill: currentColor;
  }
}
