@import '../../../main';

.button {
  height: 40px;
  //min-width: 160px;
  border: 0;
  background: none;
  padding: 0 20px;

  line-height: 40px;

  font-family: $font-secondary;
  font-weight: 500;
  font-size: 16px;
  text-align: center;

  color: $greyish-brown;

  @include media('<=phone') {
    font-size: 12px;
    padding: 0 10px;
    min-width: 80px;
  }

  i {
    font-size: 40px;
  }
}

.button:hover {
  background: none;
  color: $viridian;
}

.button:focus {
  background: none;
}
