@import '../../../../main';

.toggle {
  margin-top: 20px;
  padding-bottom: 10px;


  label {
    font-size: 13px;
  }


  label input[type=checkbox]:checked + .lever:after {
    background-color: $viridian;

  }
}
